import React, { memo } from "react";
import Stack from "@mui/material/Stack";
import { SessionContext, SessionContextInterface } from "../../hooks/SessionHook/SessionContext";
import Link from "next/link";
import { EPage } from "../../helpers/constants";
import Box from "@mui/material/Box";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";
import { EDIcon, EDIconSize, edWebkitTheme, grey, info, spacing } from "@trailblazer-game/ed-webkit";
import { useWallet } from "@solana/wallet-adapter-react";
import { styled } from "@mui/material/styles";
import { PRODUCTION } from "../../helpers/environment";
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import { theme } from "../Theme/Theme";
import LogoutIcon from '@mui/icons-material/Logout';

interface IAppBarUserMenuProps {
  className?: string;
  handleUserMenuChange: (e: React.MouseEvent<HTMLDivElement>, page: EPage) => void;
}

const AppBarUserMenu = memo(({ className, handleUserMenuChange }: IAppBarUserMenuProps) => {
  const { disconnect } = useWallet();
  const { clearSession, identity } = React.useContext(SessionContext) as SessionContextInterface;
  const router = useRouter();
  const { t } = useTranslation();

  const logout = async () => {
    clearSession();
    await disconnect();

    router.push("/login");
  }

  return <Stack className={`${className} user-menu card-shadow`} alignItems={"stretch"}
    justifyContent={"flex-end"}>

    {/*<Link href={`/${EPage.PROFILE}`} passHref>
      <Box className={router.pathname == `/${EPage.PROFILE}` ? "link active" : "link"}
           onClick={(e) => handleUserMenuChange(e, EPage.PROFILE)}>
        {t("menu.profile")}
      </Box>
    </Link>*/}

    {/*<Box className={"separator"}/>*/}

    <Stack style={{fontFamily: "ArchivoBlackRegular", fontSize: "12px", fontWeight: 200, textTransform: "uppercase"}} direction={'row'} justifyContent='center' alignItems='center' className={"user-menu-link logout"}
      onClick={logout}>
        <EDIcon icon={ <LogoutIcon style={{marginRight: spacing(1)}} fontSize="small"/>} size={EDIconSize.Small} />
      {t("menu.logout")}
    </Stack>

    {!PRODUCTION && false && <>
      <Box className={"separator2"} />
      <Stack direction={"row"} alignItems={"center"} justifyContent={"flex-end"}>
        <EDIcon icon={<DesignServicesIcon sx={{ color: grey("50") }} />} size={EDIconSize.Small} />
        <Box className={"user-menu-link debug"}
          onClick={() => document.body.classList.toggle("debug-rects")}>
          Show boundaries
        </Box>
      </Stack>
    </>}
  </Stack>
});

AppBarUserMenu.displayName = "AppBarUserMenu";

const AppBarUserMenuStyled = styled(AppBarUserMenu)({
  position: "absolute",
  bottom: 0,
  right: 0,
  backgroundColor: "white",
  transform: `translate(0, calc(100% + ${spacing(1)}))`,
  transformOrigin: '0 100%',
  margin: '0',
  padding: edWebkitTheme.spacing(0.5, 2),
  width: 'auto',
  borderRadius: '12px',
  ".user-menu-link": {
    //textTransform: "uppercase",
    fontSize: "17px",
    fontWeight: 900,
    color: "#f100db",
    fontFamily: "Outfit",
    textAlign: "end",
    width: "100%",
    padding: 0,
    margin: `${theme.spacing(0.5, 0)}`,
    justifyContent: "flex-end",
    "&.debug": {
      ...theme.typography.caption,
      color: info("main")
    }
  },
  '> .separator': {
    height: 'auto',
    width: '100%',
    border: `1px solid ${grey(800)}`,
    opacity: '0.5',
    margin: theme.spacing(0.5, 0)
  }
});

export default AppBarUserMenuStyled;