import React, { useEffect, useState } from "react";
import { styled, useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import MenuIcon from '@mui/icons-material/Menu';
import LocaleChanger from '../LocaleChanger';
import MainMenu from "../Menu/main/MobileMainMenu";
import Link from "next/link";
import { SessionContext, SessionContextInterface } from "../../hooks/SessionHook/SessionContext";
import { theme } from "../Theme/Theme";
import Stack from "@mui/material/Stack";
import { useRouter } from "next/router";
import { EPage } from "../../helpers/constants";
import { facade } from "../../helpers/analytic/AnalyticFacade";
import { MenuPageEvent } from "../../helpers/analytic/event/MenuPageEvent";
import ArticleIcon from "@mui/icons-material/Article";
import AppBar_ProfileButton from "./AppBar_ProfileButton";
import AppBarBackground from "./AppBarBackground";
import Image from "next/image";
import { active, EDIcon, EDIconSize, EDTypography, error as edError, grey, spacing } from "@trailblazer-game/ed-webkit";
import { error, log } from "../../helpers/utils/log";


export interface IMenuOption {
  id: string,
  label: string,
  icon?: React.ReactNode,
  active?: boolean,
  link?: string,
  content?: React.ReactNode;
  className?: string;
  items?: Partial<IMenuOption>[]
}

function AppBarTop(props: any) {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<undefined | HTMLElement>(undefined);
  const { hasSession } = React.useContext(SessionContext) as SessionContextInterface;

  const showMenuText = useMediaQuery(theme.breakpoints.up("lg"));

  useEffect(() => {

  }, []);

  const DEV = false;
  const { t } = useTranslation();

  const openCommMenu = Boolean(anchorEl);
  const router = useRouter();
  const bHasSession = hasSession();

  // Menu for small screens! 
  const menuOpts: IMenuOption[] = [
    {
      id: "whySupercontent",
      label: t("menu.superplay_home"),
      icon: <ArticleIcon />,
      active: true,
      link: `/${EPage.HOME}`
    },
    {
      id: "collection",
      label: t("menu.collection"),
      icon: <ArticleIcon />,
      active: true,
      link: `/${EPage.COLLECTION}`
    },
    {
      id: "console",
      label: t("menu.console"),
      icon: <ArticleIcon />,
      active: true,
      link: `/${EPage.CONSOLE}/campaigns`
    }
  ].filter((el: any) => el.active);

  //
  const handleClick = (event: any) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleMainMenuClose = (e: any, page: string) => {
    e.stopPropagation();

    handleMainMenuChange(e, page);
    setAnchorEl(undefined);
  };

  const handleMainMenuChange = (event: React.SyntheticEvent, newValue: string) => {
    facade.sendEvent(new MenuPageEvent(newValue));
  };

  // Normal top header menu!
  const renderDesktopMenu = () => {
    return (
      <Stack direction={"row"} alignItems={"center"} justifyContent={"flex-start"}
        className={'links-container'}>

        <Link href={`/`} passHref>
          <a>
            <Box className={"sc-logo"} onClick={(e) => handleMainMenuChange(e, "homepage")}>
              <Image alt="Superplay logo" src="/supercontent/logo.webp" style={{ width: "150px" }} layout={"fill"} />
            </Box>
          </a>
        </Link>

        <Stack direction={"row"} alignItems={"stretch"} justifyContent={"flex-start"} className={'nav-left'}>

        </Stack>

        {DEV &&
          <>

          </>
        }
      </Stack>
    );
  }

  // For smaller screens!
  const renderMobileMenu = () => {
    return (
      <Stack direction={'row'} className={'mobile-menu-container'} alignItems={"center"} justifyContent={"flex-start"} spacing={2}>

        <MenuIcon onClick={handleClick} sx={{
          color: "white",
          fontSize: "24px",
          cursor: "pointer",
          zIndex: 1000
        }} />

        <Link href={`/`} passHref>
          <a>
            <Box className={"sc-logo"} onClick={(e) => handleMainMenuChange(e, "homepage")}>
              <Image alt="Superplay logo" src="/supercontent/logo.webp" layout={"fill"} />
            </Box>
          </a>
        </Link>

        <MainMenu menuOpts={menuOpts} anchorEl={anchorEl} open={openCommMenu} handleClose={handleMainMenuClose} />
      </Stack>
    )
  }

  //
  return (
    <Stack className={props.className} spacing={2} direction={"row"}>
      <AppBarBackground />

      {/* Left side */}
      {showMenuText && renderDesktopMenu()}
      {!showMenuText && renderMobileMenu()}

      {/* Right side */}
      <AppBar_ProfileButton handleMainMenuChange={handleMainMenuChange} />

      {/* Change UI language */}
      {showMenuText && DEV &&
        <LocaleChanger siteLang={props.siteLang} mystyle={{ margin: "0px 20px 0px 15px" }} />}
    </Stack>
  );
}

const AppBarTopStyled = styled(AppBarTop)({
  boxShadow: 'none',
  maxWidth: `1920px`,
  zIndex: 1000, //theme.zIndex.drawer + 1,
  position: 'fixed',
  top: 0,
  right: 0,
  left: 0,
  padding: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  ['@media (min-width:1920px)']: {
    left: 'calc(50vw - 976px)',
  },
  'span, p, a': {
    letterSpacing: '0.1rem',
  },
  '.links-container': {
    height: '56px',
    width: '100%',
    position: 'relative',
    ['@media (max-width:1000px)']: {
      width: '100vw',
      maxWidth: '100vw',
    }
  },
  '.link': {
    background: "linear-gradient(to right, #ee1cfe, #f100dd, #ee00be, #e700a2, #dc0f8a)",
    borderRadius: spacing(10),
    padding: theme.spacing(1.2, 2, 1.2, 2),
    textTransform: 'uppercase',
    color: grey('50'),
    fontWeight: '500',
    textShadow: "1px 1px 2px #b817e1",
    cursor: 'pointer',
    fontSize: '0.75rem',
    fontFamily: 'ArchivoBlackRegular',
    letterSpacing: '0.1rem',
    textAlign: "right",
    '&:hover': {
      textShadow: `1px 1px 4px red`
    },
    '&.active': {
      background: "#fffffff3",
      borderRadius: spacing(10),
      color: "#b817e1!important",
      boxShadow: "0px 0px 7px 0px #33333388",
      textShadow: "none",
      //fontWeight: 900,
    },
    "&.logout": {
      color: edError("main")
    }
  },
  '.mobile-menu-container': {
    width: '100%',
    //padding: theme.spacing(1.5),
    marginLeft: 0
  },
  'a': {
    display: 'inline-flex',
    height: '100%'
  },
  '.sc-logo': {
    height: '50px',
    aspectRatio: '1.42',
    position: 'relative',
    cursor: "pointer",
    '&.mobile': {
      aspectRatio: '1',
      height: '40px'
    }
  },
  "#profile-button, #login-button": {
    background: theme.palette.getContrastText(theme.palette.secondary.dark),
    padding: "0",
    fontWeight: 900,
    "& > .top-bar-right-menu-text": {
      padding: `${theme.spacing(1)} ${theme.spacing(4)}`,
      color: theme.palette.secondary.dark,
    },
    "&#login-button": {
      background: theme.palette.secondary.main,
      "& > .top-bar-right-menu-text": {
        padding: `${theme.spacing(1)} ${theme.spacing(4)}`,
        color: theme.palette.getContrastText(theme.palette.secondary.main)
      }
    }
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0.5, 1, 0.5, 1)
  }
});

export default AppBarTopStyled;