import { DESCENDANT_EGG_LABEL, DRAGON_LABEL, ENTRY_TICKET_LABEL, PLAYER_CARD_LABEL } from "./interfaces";

// Calc summary rank stats!
export function calcRankStats(seasonRanks: any[]) {

    // Filter ranks with nulls for stats!
    let fRanks = seasonRanks;//.filter((r: any) => r.rank !== null);

    // All have nulls!
    if (fRanks.length === 0) {
        return {
            highestRank: null,
            winRate: null,
            totalGamesPlayed: null,
            highestRating: null,
            wins: null,
            trophies: null
        }
    }

    let highestRank = fRanks[0].rank; // can be null!!
    let winRate = fRanks[0].plays * fRanks[0].ratio;
    let totalGamesPlayed = fRanks[0].plays;
    let highestRating = fRanks[0].rating; // can be null!!
    let wins = fRanks[0].wins;
    let trophies = fRanks[0].trophies;

    for (let i = 1; i < fRanks.length; i++) {
        let fRank = fRanks[i];

        if ((fRank.rank !== null && fRank.rank < highestRank) || highestRank == null)
            highestRank = fRank.rank;

        if ((fRank.rating !== null && fRank.rating > highestRating) || highestRating === null)
            highestRating = fRank.rating;

        winRate += (fRank.plays * fRank.ratio);
        totalGamesPlayed += fRank.plays;
        wins += fRank.wins;
        trophies += fRank.trophies;
    }

    return {
        highestRank,
        winRate: totalGamesPlayed > 0 ? (winRate / totalGamesPlayed) : 0,
        totalGamesPlayed,
        highestRating,
        wins,
        trophies
    }
}

//
export function getJoinDateStr(createdAtTs: number) {
    let dateSplit = (new Date(createdAtTs).toString()).split(" ");
    return `${dateSplit[1]} ${dateSplit[3]}`;
}

//
export function getLabelByIndex(index: number, nft: any) {
    if (index > 2000000000000) {
        return nft.isPlayerCard ? PLAYER_CARD_LABEL : ENTRY_TICKET_LABEL;
    }
    else if (index > 1000000000000) {
        return DESCENDANT_EGG_LABEL;
    }
    else {
        return DRAGON_LABEL;
    }
}

//
export function valUi(val: any, perc: boolean, pref: string, suf: string) {
    return (val !== null && val !== undefined) ? (pref + (perc ? Math.round(val * 100) : val) + suf) : ("-");
}

export function getIndexFromName(name: string) {
    return parseInt(name?.replace(/.+(♯|#)/, ""));
}

export function sortByNumberInName(items: any) {
    return [...items].sort((a, b) => getIndexFromName(a.name) - getIndexFromName(b.name));
}