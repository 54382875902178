import * as React from 'react';
import { memo, useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from 'react-i18next'
import Link from 'next/link';
import Stack from "@mui/material/Stack";
import { EDTypography, edWebkitTheme, grey, spacing } from "@trailblazer-game/ed-webkit";
import { useRouter } from "next/router";
import { SessionContext, SessionContextInterface } from "../../hooks/SessionHook/SessionContext";
import { EPage } from "../../helpers/constants";
import { theme } from '../Theme/Theme';

interface IFooterProps {
  className?: string;
}

// Component
const Footer = memo((props: IFooterProps) => {
  const theme = useTheme();
  const router = useRouter();
  const { t } = useTranslation();
  const { hasSession } = React.useContext(SessionContext) as SessionContextInterface;
  const bHasSession = hasSession();
  const mobile = useMediaQuery(theme.breakpoints.up(500));

  return <Box className={props.className}>
    <Stack className="footer-cont" direction={mobile ? 'row' : 'column'} spacing={2} alignContent="start" justifyContent={'space-between'}>

      <Stack direction={'column'} spacing={0}>
        <Link rel="noreferrer" href={'/'} >
          <a className={"sp-logo"}> <img alt="Superplay Logo" src="/supercontent/logo.webp" /> </a>
        </Link>

        <EDTypography variant="body2" sx={{ color: grey(50), width: "100%", fontWeight: 900 }}>
          2023 Superplay
        </EDTypography>
      </Stack>

      <Stack direction={'column'} spacing={1} justifyContent="center" style={{ marginTop: spacing(1) }}>
        <Stack direction={'row'} spacing={1}>

          <Link href={`/${EPage.COLLECTION}`} passHref>
            <a>
              <Box className={'link'}>
                {t("menu.collection")}
              </Box>
            </a>
          </Link>

          <Link href={`/${EPage.TERMS_AND_CONDITIONS}`} passHref>
            <a>
              <Box className={'link'}>
                {t("menu.terms")}
              </Box>
            </a>
          </Link>

          <Link href={`/${EPage.PRIVACY_POLICY}`} passHref>
            <a>
              <Box className={'link'}>
                {t("menu.privacy")}
              </Box>
            </a>
          </Link>
        </Stack>

        <Stack direction={'row'} justifyContent='end' spacing={1} className="social-cont">
          <a rel="noreferrer" target="_blank" href={'/'} className={"sp-social"}>
            <img alt="Superplay Social" src="/supercontent/social/discord.webp" />
          </a>

          <a rel="noreferrer" target="_blank" href={'/'} className={"sp-social"}>
            <img alt="Superplay Social" src="/supercontent/social/instagram.webp" />
          </a>

          <a rel="noreferrer" target="_blank" href={'/'} className={"sp-social"}>
            <img alt="Superplay Social" src="/supercontent/social/x.webp" />
          </a>

          <a rel="noreferrer" target="_blank" href={'/'} className={"sp-social"}>
            <img alt="Superplay Social" src="/supercontent/social/at.webp" />
          </a>
        </Stack>

      </Stack>
    </Stack>
  </Box>
});

Footer.displayName = "Footer";

// Default style!
const FooterStyled = styled(Footer)({
  backgroundImage: "url('/supercontent/footer.webp')",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "top right 18%",
  backgroundSize: "cover",
  padding: edWebkitTheme.spacing(5, 5, 3, 5),
  position: 'relative',
  maxWidth: '1920px',
  marginTop: "-24px", // Becasue footer has wave and transparency!
  width: "100%",
  'span, p': {
    fontFamily: 'Figtree',
  },
  ['@media (max-width:1000px)']: {
    //padding: edWebkitTheme.spacing(6, 2, 12, 2)
  },
  '.link': {
    margin: theme.spacing(0, 1, 0, 1),
    textTransform: 'uppercase',
    color: edWebkitTheme.palette.grey['50'],
    cursor: 'pointer',
    ...edWebkitTheme.typography.body2,
    fontWeight: 700,
    '&.active': {
      color: '#ffea9a'
    },
    ['@media (max-width: 650px)']: {
      fontSize: '0.75rem',
      margin: 0,

    }
  },
  '.sp-logo': {
    'img': {
      width: '80px',
      ['@media (max-width: 800px)']: {
        width: '80px',
      }
    }
  },
  '.sp-social': {
    'img': {
      width: '25px',
      margin: theme.spacing(0, 0.5, 0, 0.5),
      ['@media (max-width: 800px)']: {
        marginTop: spacing(1)
      }
    }
  },
  ".social-cont": {

    ['@media (max-width: 499px)']: {
      justifyContent: "start"
    }
  }
});

// Export
export default FooterStyled;
