// Navigation
export enum EPage {
  HOME = "",
  ABOUT = "about",
  COMMUNITY = "community",
  PRIVACY_POLICY = "privacy-policy",
  TERMS_AND_CONDITIONS = "terms-and-conditions-of-service",
  PROFILE = "profile",
  LOGIN = "login",
  NOTFOUND = 'notfound',
  REGISTER = 'register',
  WELCOME = "welcome",
  NOTIFICATIONS = "user/notifications",
  PUBLIC_PROFILE = "u",
  WHY_SUPERPLAY = "whySuperPlay",
  PARTNERS = "partners",
  COLLECTION = "collection",
  CONSOLE = "console"

}

export const SOCIAL_URLS: any = {
  medium: "https://eternaldragons.medium.com/",
  discord: "https://discord.gg/eternaldragons",
  twitter: "https://twitter.com/EternalDragons_"
}