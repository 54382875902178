import { edWebkitTheme } from "@trailblazer-game/ed-webkit";

let _theme = edWebkitTheme;

// We override here ed-webkit theme!
_theme.typography.h1.fontFamily = "ArchivoBlackRegular";
_theme.typography.h1.fontWeight = 500;

_theme.typography.h2.fontFamily = "ArchivoBlackRegular";
_theme.typography.h2.fontWeight = 500;

_theme.typography.h3.fontFamily = "ArchivoBlackRegular";
_theme.typography.h3.fontWeight = 500;

_theme.typography.h4.fontFamily = "ArchivoBlackRegular";
_theme.typography.h4.fontWeight = 500;

_theme.typography.h5.fontFamily = "ArchivoBlackRegular";
_theme.typography.h5.fontWeight = 500;

_theme.typography.h6.fontFamily = "ArchivoBlackRegular";
_theme.typography.h6.fontWeight = 500;

_theme.typography.body1.fontFamily = "PoppinsRegular";
_theme.typography.body2.fontFamily = "PoppinsRegular";

_theme.header.height.sm = '80px';
export const theme = _theme;