import React from "react";
import Stack from "@mui/material/Stack";
import { SessionContext, SessionContextInterface } from "../../hooks/SessionHook/SessionContext";
import Link from "next/link";
import { EPage } from "../../helpers/constants";
import Box from "@mui/material/Box";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";
import {
    EDButton,
    EDButtonType,
    EDPlayerAvatar,
    EDTypography,
    edWebkitTheme,
    error,
    grey,
    primary,
    spacing
} from "@trailblazer-game/ed-webkit";
import { truncateString } from "../../helpers/utils/string";
import { facade } from "../../helpers/analytic/AnalyticFacade";
import { MenuPageEvent } from "../../helpers/analytic/event/MenuPageEvent";
import useComponentVisible from "../../hooks/useComponentVisible";
import { useWallet } from "@solana/wallet-adapter-react";
import AppBar_NotificationCounter from "./AppBar_NotificationCounter";
import { styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import AppBar_UserMenu from "./AppBar_UserMenu";
import Image from "next/image";
import { theme } from "../Theme/Theme";

interface IAppBarProfileButtonProps {
    className?: string;
    handleMainMenuChange: (e: React.MouseEvent<HTMLDivElement>, link: string) => void;
}

function AppBarProfileButton(props: IAppBarProfileButtonProps) {
    const { disconnect } = useWallet();
    const { hasSession, identity, tbUid, clearSession } = React.useContext(SessionContext) as SessionContextInterface;
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
    const bHasSession = hasSession() && identity;
    const router = useRouter();
    const { t } = useTranslation();
    const isSmall = useMediaQuery(edWebkitTheme.breakpoints.down('sm'));

    const getProfileName = () => {
        let name = identity.nickname || identity.name || "";
        return truncateString(name, 50);
    }

    function handleUserMenuChange(e: React.MouseEvent<HTMLDivElement, MouseEvent>, page: EPage): void {
        setIsComponentVisible(false);
        props.handleMainMenuChange(e, page);
    }

    const onProfileNameClick = (event: React.MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setIsComponentVisible(!isComponentVisible);
    }

    const handleMainMenuChange = (event: React.SyntheticEvent, newValue: string) => {
        facade.sendEvent(new MenuPageEvent(newValue));
    };

    return <Stack className={props.className} direction={"row"} alignItems={"center"} justifyContent={"center"} spacing={2}>

        {bHasSession && identity &&
            <Stack spacing={2} direction={"row"} alignItems={"center"} justifyContent={"center"} ref={ref}>

                <Link href={`/${EPage.COLLECTION}`} passHref>
                    <a>
                        <Box className={router.pathname == "/" + EPage.COLLECTION ? "link active" : "link"}
                            onClick={(e) => handleMainMenuChange(e, "collection")}>
                            {t("menu.collection")}
                        </Box>
                    </a>
                </Link>

                <Link href={`/${EPage.CONSOLE}/campaigns`} passHref>
                    <a>
                        <Box className={router.pathname.startsWith("/" + EPage.CONSOLE) ? "link active" : "link"}
                            onClick={(e) => handleMainMenuChange(e, "console")}>
                            {t("menu.console")}
                        </Box>
                    </a>
                </Link>

                <Stack className="profile-avatar-cont" direction={"row"} alignItems={"center"} justifyContent={"center"} spacing={1} onClick={onProfileNameClick}>
                    {isComponentVisible && <AppBar_UserMenu handleUserMenuChange={handleUserMenuChange} />}

                    <Box className={"profile-avatar"}>
                        <EDPlayerAvatar avatarUrl={`${process.env.NEXT_PUBLIC_CDN_URL}/avatar/${tbUid}?ts=${new Date().getTime()}`} />
                    </Box>

                    <EDTypography variant={"caption"} className={"profile-name"}
                        title={identity.nickname || identity.name || "Your profile"} >
                        {getProfileName()}
                    </EDTypography>
                </Stack>
            </Stack>
        }

        {!bHasSession &&
            <Link href={`/${EPage.LOGIN}#email`} passHref>
                <Box className={router.pathname == "/login" ? "link active" : "link"}
                    onClick={(e) => props.handleMainMenuChange(e, "login")}>
                    {t(`menu.login`)}
                </Box>
            </Link>
        }

        {/*<Image style={{ display: "block" }} width="180px" height="40px" alt="Eternal Dragons Logo" src="/supercontent/sign_up_btn.png" />*/}

        {/*bHasSession && <AppBar_NotificationCounter />*/}
    </Stack>
}

const AppBarProfileButtonStyled = styled(AppBarProfileButton)({
    position: "relative",
    cursor: "pointer",
    paddingRight: spacing(2),
    ".profile-name": {
        fontWeight: "bolder",
        color: grey(50),
        textTransform: "none",
        whiteSpace: "nowrap",
        fontFamily: 'ArchivoBlackRegular',
    },
    '.game-link': {
        border: `3px solid ${edWebkitTheme.activeColors.primary}`,
        height: '32px',
        padding: edWebkitTheme.spacing(0, 2),
        backgroundColor: edWebkitTheme.activeColors.primary,
        color: edWebkitTheme.palette.grey['900'],
        fontWeight: '700',
        display: 'flex',
        placeContent: 'center',
        placeItems: 'center',
        borderTopRightRadius: '10px',
        borderBottomLeftRadius: '10px',
        transition: 'all .25s ease-in-out',
        '&:hover': {
            textShadow: `none`,
            backgroundColor: edWebkitTheme.palette.grey['900'],
            color: edWebkitTheme.activeColors.primary
        },
        '&.active': {
            backgroundColor: edWebkitTheme.palette.primary.light,
            color: edWebkitTheme.palette.grey['900']
        }
    },
    [edWebkitTheme.breakpoints.down("sm")]: {
        paddingRight: spacing(2),
        ".profile-name": {
            display: "none"
        }
    },
    ".profile-avatar": {
        marginLeft: 0,
        textWeight: 100
    },
    ".profile-avatar > .MuiBox-root": {
        minHeight: "30px",
        minWidth: "30px",
        height: "30px",
        width: "30px",
        border: "none"
    },
    ".profile-avatar-cont": {
        
        position: 'relative', 
        boxShadow: "0px 0px 7px 0px #33333388", 
        backgroundImage: "linear-gradient(to right, #ee1cfe, #f100dd, #ee00be, #e700a2, #dc0f8a)", 
        borderRadius: spacing(10), 
        padding: theme.spacing(0.5, 1, 0.5, 0.5),
        [edWebkitTheme.breakpoints.down("sm")]: {
            padding: theme.spacing(0.5),
        }
    }
});

export default AppBarProfileButtonStyled;