import React from 'react';
import './../i18n/config';


/**
 * Handles all store controllers
 */
function Stores() {
    return <>
       
    </>;
}

export default Stores;
