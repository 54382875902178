import dynamic from 'next/dynamic';
import React from 'react';
import './../i18n/config';
import { SessionProvider } from '../hooks/SessionHook/SessionContext';
import { UserDataProvider } from "../hooks/UserDataHook/UserDataContext";
import { AnonymousIdProvider } from '../hooks/AnonymousIdHook/AnonymousIdContext';
import { WalletAdapterProvider } from "../hooks/WalletAdapterHook/WalletAdapterContext";
import { theme } from "../components/Theme/Theme";
import WalletConnectionProvider from '../components/WalletConnectionProvider';
import { ParallaxProvider } from "react-scroll-parallax";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

const ThemeProvider: any = dynamic<{}>(
    {
        loader: () => import("@mui/material/styles").then((module: any) => module.ThemeProvider),
        suspense: false,
        ssr: false
    }
)

function Providers(props: { children: React.ReactNode | React.ReactNode[] }) {

    return (
        <>
            <WalletConnectionProvider>
                <AnonymousIdProvider>
                    <SessionProvider>
                        <UserDataProvider>
                            <WalletAdapterProvider>
                                <ThemeProvider theme={theme}>
                                    <ParallaxProvider>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            {props.children}
                                        </LocalizationProvider>
                                    </ParallaxProvider>
                                </ThemeProvider>
                            </WalletAdapterProvider>
                        </UserDataProvider>
                    </SessionProvider>
                </AnonymousIdProvider>
            </WalletConnectionProvider>
        </>
    );
}

export default Providers;
